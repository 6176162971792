<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <span class="mr-1" style="width: 250px;">Start Date</span>
          <flat-pickr v-model="startDate" class="form-control mr-1"/>
          <span class="mr-1" style="width: 250px;">End Date</span>
          <flat-pickr v-model="endDate" class="form-control mr-1"/>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="No record found"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value"></id-column-table>
      </template>

      <template #cell(created_date)="data">
        <date-time-column-table :data="data.value"></date-time-column-table>
      </template>

      <template #cell(member)="data">
        {{ data.item.name }} {{ data.item.lastname }}
      </template>

      <template #cell(amount)="data">
        ${{ data.item.amount }}
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
  ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue";
import {avatarText, getApiFile} from "@core/utils/filter";
import flatPickr from 'vue-flatpickr-component'
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue";

export default {
  components: {
    DateTimeColumnTable,
    BFormInvalidFeedback, BFormGroup,
    HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable,
    DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable,

    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,

    flatPickr,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      startDate,
      endDate,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      fetchList,
      reFetchData,
    } = useList()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      startDate,
      endDate,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      fetchList,
      reFetchData,
      avatarText,
      getApiFile,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
